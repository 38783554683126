/*! 2025-4-2 20:17:05 */
[data-v-8214e38c]:export{menu-color:rgba(255,255,255,0.95);menu-color-active:rgba(255,255,255,0.95);menu-background:#282c34;column-second-menu-background:#fff}.footer .el-button+.el-button[data-v-8214e38c]{margin-left:30px}.ml15[data-v-8214e38c]{margin-left:15px}.loc-item[data-v-8214e38c]{display:flex;align-items:center}.mt20[data-v-8214e38c]{margin-top:20px}.pointer[data-v-8214e38c]{cursor:pointer}.error-p[data-v-8214e38c]{position:absolute;left:249px;top:40px;color:#ff4d4f;font-size:12px}[data-v-8214e38c] .loc-wrapper .el-input-number--small{width:100px !important}[data-v-8214e38c] .el-input-number--small .el-input__inner{padding:0 10px !important}[data-v-8214e38c] .el-input-number--small .el-input-number__increase,[data-v-8214e38c] .el-input-number--small .el-input-number__decrease{display:none !important}


.inlineFlex[data-v-08bab946] {
  display: inline-flex;
}

[data-v-358692f6]:export{menu-color:rgba(255,255,255,0.95);menu-color-active:rgba(255,255,255,0.95);menu-background:#282c34;column-second-menu-background:#fff}[data-v-358692f6] .font-class{text-align:right;line-height:18px;padding-right:10px;margin-top:10px}

[data-v-07fbaa3e]:export{menu-color:rgba(255,255,255,0.95);menu-color-active:rgba(255,255,255,0.95);menu-background:#282c34;column-second-menu-background:#fff}.popover-title[data-v-07fbaa3e]{display:inline-block;width:16px;height:16px;line-height:16px;text-align:center;font-size:13px;margin-left:5px;position:absolute;bottom:10px;right:10px}.popover-title[data-v-07fbaa3e]:hover{cursor:pointer}.green-box[data-v-07fbaa3e]{background:rgba(112,182,3,0.2);color:#70b603}.origin-box[data-v-07fbaa3e]{background:rgba(245,154,35,0.2);color:#f59a23}

[data-v-5df44356]:export{menu-color:rgba(255,255,255,0.95);menu-color-active:rgba(255,255,255,0.95);menu-background:#282c34;column-second-menu-background:#fff}.table-container[data-v-5df44356]{padding:0 20px 20px !important}.table-container .title[data-v-5df44356]{display:flex;align-items:center}.table-container .detail-fields .el-row .label[data-v-5df44356]{color:#282c34;width:100px;text-align:right;display:inline-block;margin-right:15px}.table-container .detail-fields .el-row .content[data-v-5df44356]{color:#000016}.detail-label[data-v-5df44356]{font-size:14px;color:#606266}.detail-content[data-v-5df44356]{font-size:16px !important;margin-top:10px;font-weight:normal}.header[data-v-5df44356]{font-size:18px;color:#606266}.fs14[data-v-5df44356]{font-size:14px;font-family:'PingFang Bold';color:#282c34}.mr15[data-v-5df44356]{margin-right:15px}.pointer[data-v-5df44356]{cursor:pointer}.loc-content[data-v-5df44356]{max-height:300px;overflow:auto}.costTable[data-v-5df44356]{height:calc(100vh - 260px);overflow-y:auto}


/*# sourceMappingURL=chunk-6c09ff1d.2879166d.css.map*/